import {Box, Grommet, Heading, Text, Image} from "grommet";
import AppBar from './components/AppBar'
import styled from "styled-components";
import ToolsBlurred from './images/tools-blurred.jpg';

const theme = {
  global: {
    colors: {
      primary: '#1A1A1C',
      secondary: '#E81C2E',
      background: '#FFFAFF'
  },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

const NavBarItem = styled(Text)`
  margin-right: 20px;
`
const HeaderImage = styled(Image)`
  width: 80%;
`
const HeaderText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 30px;
`

function App() {
  return (
    <Grommet theme={theme} full>
      <AppBar>
        <Heading level='3' margin='none' color='secondary'>Transilvania Royal Cars</Heading>
        <Box direction='row'>
          <NavBarItem></NavBarItem>
          <NavBarItem></NavBarItem>
        </Box>
      </AppBar>
      <Box color='background' direction='row' flex overflow={{ horizontal: 'hidden' }}>
        <Box flex align='center' justify='center'>
          <HeaderText color='primary'>IN CURAND</HeaderText>
          <HeaderImage fit="cover" src={ToolsBlurred}/>
        </Box>
      </Box>
    </Grommet>
  );
}

export default App;
